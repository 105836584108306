import React, { Children, useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  imageUpload,
  createGuide,
  behaviourReportsAll,
  updateGuide,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import { useWebSelector } from "../../redux/selector/web";

const UpdateGuide = (props) => {

  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const webData = useWebSelector();
  const [guideTitle, setGuideTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [allguides, setAllGuides] = useState("");
  const [selectedMinute, setSelectedMinute] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [value, setValue] = useState("");
  const [key, setKey] = useState(Math.random());
  const [guideId, setguideId] = useState("");
  const [files, setFiles] = useState([]);
  const [EditFiles, setEditFiles] = useState(props?.item?.guide_images || []);
  const isEdit = props?.isEdit;
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });



 
  const handleMinuteChange = (selectedOption) => {
    setSelectedMinute(selectedOption);
  };

  const handleImageRemove = (index) => {
    if (index < EditFiles.length) {
      const updatedEditFiles = [...EditFiles];
      updatedEditFiles.splice(index, 1);
      setEditFiles(updatedEditFiles);
    } else {
      const updatedFiles = [...files];
      updatedFiles.splice(index - EditFiles.length, 1);
      setFiles(updatedFiles);
    }
  };

  const minutesOptions = Array.from({ length: 60 }, (_, i) => ({
    value: i * 10,
    label: i < 10 ? `${i}0` : `${i * 10}`, // Display minutes in the format you want
  }));

 

  const handleFileChange = async (e) => {
    setLoading(true);
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
    setLoading(false);
  };

  const onSave = (data) => {
    if (
      !guideTitle?.trim() ||
      /^\s*$/.test(guideTitle) ||
      guideTitle.length < 2
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "GuideTitle should not be empty, consist only of whitespace, or be less than 2 characters"
        );
      }
      return;
    }

    if (
      !description?.trim() ||
      /^\s*$/.test(description) ||
      description.length < 2
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Description should not be empty, consist only of whitespace, or be less than 2 characters"
        );
      }
      return;
    }

    if (!guideTitle) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Add Guide Title");
        return false;
      }
    }
    if (!description) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Add the Description ");
        return false;
      }
    }
    if (!selectedMinute) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select the Minute");
        return false;
      }
    }
    if (!selectedGuide) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select the Guide");
        return false;
      }
    }
    const formData = new FormData();

    if (files?.length > 0) {
      files.forEach((file) => {
        formData.append(`file`, file);
      });
    }
    let params = {
      file: files,
    };

    let resultUrls;
    const create = (filePaths) => {
      const updateParams = {
        id: props?.item?.id,
        title: guideTitle,
        duration: selectedMinute.value,
        description: description,
        behavior_report_id: selectedGuide.value.toString(),
        guide_images: [...filePaths, ...EditFiles],
      };

      dispatch(
        updateGuide({
          ...updateParams,
          cb(res) {
            if (res.status === 200) {
              props?.oneGuide();
              props?.close();
            } else {
              console.log("Something Went Wrong");
            }
          },
        })
      );
    };

    if (files?.length > 0 || !isEdit) {
      dispatch(
        imageUpload({
          ...params,
          cb(res) {
            if (res.status === 200) {
              resultUrls = res?.data?.payload;
              const filePaths = resultUrls.map((item) => item.filePath);
              create(filePaths);
              props?.close();
              props?.getallguides();
            } else {
              console.log("Something Went Wrong");
            }
          },
        })
      );
    } else {
      create([]);
    }
  };

  const allBehaviourReports = () => {
    dispatch(
      behaviourReportsAll({
        cb(res) {
          if (res?.data) {
            setAllGuides(res?.data?.payload);

            let filterResult = res?.data?.payload?.map((item) => {
              return {
                value: item?.id,
                label: item?.title,
              };
            });

            let guideResult = filterResult?.find(
              (item) => item?.value == props?.item?.behavior_report_id
            );
            setSelectedGuide(guideResult ? guideResult : "");
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleGuideChange = (selectedOption) => {
    setSelectedGuide(selectedOption);
  };

  
 
  useEffect(() => {
    allBehaviourReports();
  }, []);

  useEffect(() => {
    setGuideTitle(props?.item?.title ? props?.item?.title : []);
    setDescription(props?.item?.description ? props?.item?.description : []);
    setSelectedMinute(props?.item?.duration ? props?.item?.duration : []);
    setSelectedFiles(props?.item?.guide_image ? props?.item?.guide_images : []);
    setguideId(
      props?.item?.behavior_report_id ? props?.item?.behavior_report_id : []
    );
  }, []);
  useEffect(() => {
    let filterMinute = minutesOptions?.map((item) => {
      return {
        value: item?.value,
        label: item?.label,
      };
    });

    let selectMin = filterMinute.find(
      (item) => item.value == props?.item?.duration
    );

    setSelectedMinute(selectMin);
  }, []);

  return (
    <>
      <div className="form-group col-lg-12 col-md-12 col-sm-12 mb-4 ext-sttart">
        <div>
          <label className="" htmlFor="amount">
            Upload Photo <span className="subtextSmall_"></span>
          </label>
        </div>

        <div>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}

          {[...files, ...EditFiles].length ? (
            <div className="upload_image">
              {[...files, ...EditFiles].map((image, index) => (
                <div key={index} className="">

                  <img
                    src={image.name ? URL.createObjectURL(image) : image}
                    alt="Image"
                    style={{ width: "100px", height: "100px" }}
                    className="w-40 h-40 object-cover"
                  />

                  <div className="position-relative">
                    <img
                      src={images.cross}
                      alt="blackCross"
                      className="upload_position"
                      onClick={() => handleImageRemove(index)}
                    />
                  </div>
                </div>
              ))}
              {[...files, ...EditFiles].length < 10 && (
                <label htmlFor="image-upload" className="w-100">
                  <input
                    id="image-upload"
                    type="file"
                    className="d-none"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />

                  <div className="w-40 h-40 border  border-[#008B9D] w-100 py-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={images.gallery}
                        alt="GalleryIcon"
                        className="w-12 h-12"
                      />
                      <span>Upload image</span>
                    </div>
                  </div>
                </label>
              )}
            </div>
          ) : (
            <div className="w-40 h-40 border  border-[#008B9D] w-100 py-3">
              <div className="d-flex justify-content-center align-items-center">
                <label htmlFor="file">
                  <div className="flex justify-center flex-col items-center">
                    <img
                      src={images.gallery}
                      alt="GalleryIcon"
                      className="w-12 h-12"
                    />
                    <span>Upload Image</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      className="d-none"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="mb-3">
          <label className="">Title</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter guide Title"
            value={guideTitle}
            onChange={(e) => setGuideTitle(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label className="">Description</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-3 ">
          <label className="">Select Duration</label>

          <Select
            options={minutesOptions}
            value={selectedMinute}
            onChange={handleMinuteChange}
          />
        </div>

        <div className="mb-3">
          <label className="">Select guides</label>
          <Select
            value={selectedGuide}
            onChange={handleGuideChange}
            options={
              Array.isArray(allguides)
                ? allguides.map((guide) => ({
                    value: guide.id,
                    label: guide.title,
                  }))
                : []
            }
            placeholder="Select a guide"
          />
        </div>

        <div className="outer-btn">
          <button
            className=" submit-btn "
            onClick={(e) => {
              onSave(e);
            }}
          >
            {webData.loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateGuide;
