import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUserStatus, userList } from "../../redux/slices/dashboard";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "../../common/Loader";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userLists, setUserList] = useState([]);
  const [loading,setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const getallUserList = () => {
    const params = {
      page: page,
      limit: 10,
    };

    setLoading(true)
    dispatch(
      userList({
        ...params,
        cb(res) {
          if (res?.data) {
            setUserList(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
            setLoading(false)
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
 
  const handleGoToDetailPage = (data) => {
    navigate(`/userDetail/${data?.id}`);
  };

 

  const handleChangeStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 0 ? 1 : 0;
    let params = {
      userId: id,
      status: newStatus,
    };
    dispatch(
      updateUserStatus({
        ...params,
        cb(res) {
          if (res?.data) {
            setUserList((prevUserLists) => {
              return prevUserLists.map((user) => {
                if (user.id === id) {
                  return { ...user, status: newStatus };
                }
                return user;
              });
            });
            toast.success("User status updated successfully");
          } else {
            toast.error("Something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getallUserList();
  }, [page]);

  useEffect(() => {
    getallUserList();
  }, []);
  return (
    <>
      <div className="content-wrapper ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Users</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="recentUserSec">
     

        {loading && <Loader/>}
          <div className="table-responsive recentTable userRecentTable">
            <table class="table table-dark m-0">
            
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    First Name
                  </th>
                  <th scope="col" className="recentHead">
                    Last Name
                  </th>
                  <th scope="col" className="recentHead">
                    Email
                  </th>
                  <th scope="col" className="recentHead text-center">
                    Patient Info
                  </th>

                  <th scope="col" className="recentHead ">
                    Status
                  </th>
                </tr>
              </thead>

              {userLists?.length > 0 ? (
                userLists?.map((data, idx) => {
                  const index = idx + 1 + (page - 1) * 10;
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{index}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <h4 className="tableSubText">{data?.first_name}</h4>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{data?.last_name}</h4>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">
                            {data?.email}
                          </h4>
                        </td>
                        <td className="recentSubHead text-center">
                          <button
                            className="eyeBtn"
                            type="button"
                            onClick={(e) => handleGoToDetailPage(data)}
                          >
                            <img
                              src={images.eyeImg}
                              className="img-fluid "
                              alt="eyeImg"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={`${
                              data.status === 0 ? "activeUser" : "dActiveUser"
                            }`}
                            onClick={() =>
                              handleChangeStatus(data.id, data.status)
                            }
                          >
                            {data.status === 0 ? "Activate" : "Deactivate"}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                // <h3 className="text-center">No Users Found!</h3>
                ""
              )}
            </table>
            {(!userLists || userLists?.length === 0) && (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No User List Found
            </div>
          )}
          </div>
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {userLists?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
