import React from 'react'

function FaqViewModal({faq}) {
  return (
    <>
    <p className='faqData'><span className='faqSpan'>Question:</span> {faq?.question}?</p>
    <p className='faqData'><span className='faqSpan'>Answer:</span> {faq?.answer}.</p>
    </>
  )
}

export default FaqViewModal