import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  enterOtp,
  onErrorStopLoad,
  resendEnterOtp,
} from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {Link} from "react-router-dom";
import OTPInput from "react-otp-input";
import { useAuthSelector } from "../../redux/selector/auth";
import logo from "../../../src/public/images/sideLogo.png";


const EnterOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [otp, setOtp] = useState("");
  const [key, setKey] = useState(Math.random());

  const [email, setEmail] = useState("briteTraceAdmin@yopmail.com");
  const [showResendBtn, setShowResendBtn] = useState(true);

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      toast.error("Please enter your OTP");
      return;
    }
    let params = {
      email: email,
      email_otp: otp,
    };
    dispatch(
      enterOtp({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setTimeout(() => {
              setShowResendBtn(true);
            }, 5000);

            setTimeout(() => {
              navigate("/reset-password");
            }, 5000);
          }
        },
      })
    );
  };


  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // resend OTP

  const handleResendOtp = (e) => {
    e.preventDefault();
    let params = {
      email: email,
    };
    dispatch(
      resendEnterOtp({
        ...params,
        cb(res) {
          if (res.status === 200) {
            // navigate("/reset-password");
            setTimeout(() => {
              setShowResendBtn(true);
            }, 5000);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="login-page authBg adminotp">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" className="logoMain"/>
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg p-0">
          <div className="loginPage">
            <div className="card-header text-center border-0">
              <h3 className="mb-0 logintext">Enter OTP</h3>
              <p className="form_subheading mt-3">
                Enter the OTP that we just sent you on your email <br />
                address to reset your password.
              </p>
            </div>
            <div className="card-body login-card-body">
              <form onSubmit={(e) => handleSubmit(e)} className="otpform">
                <div className="input-container  ">
                  <input
                    type="text"
                    className="form-control form-input borderinput d-none"
                    name="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={setEmail}
                  />
                </div>

                <div className="otpForm">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => (
                      <input {...props} className="enterOtp" />
                    )}
                  />
                </div>

                

                <div className="buttonBox mt-5">
                  <button
                    type="submit"
                    className="loginBtnCommon btnYellow mw-100 loginbtn"
                  >
                    {authData.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp; Submit
                  </button>
                </div>
                  <p className="otpheading text-center">
                    Don’t Received?
                    <a
                      onClick={(e) => handleResendOtp(e)}
                      className="resendLink"
                    
                    >
                      Resend OTP
                    </a>
                  </p>  
                  <p className="mt-4 text-center  forgotheading">Go Back To <Link to="/" className="color-login"> Login</Link></p>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
