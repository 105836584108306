import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSymptomOption } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

function UpdateSymptomModal(props) {
  const [text, setText] = useState("");
  const [key, setKey] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const toastId = React.useRef(null);
  const [status, setStatus] = useState(1);
  const dispatch = useDispatch();

  const updateSymptom = () => {
    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Option should not be empty, consist only of whitespace, or be less than 2 characters");
      }
      return; 
    }
    const optionId = props?.item?.id;
    let params = {
      key: key,
      title: title,
      id: id,
      options: props?.oneSymptom?.options.map(option => {
        if (option.id === optionId) {
          return {
            ...option,
            text: text,
            status: status,
          };
        }
        return option;
      }),
    };
    dispatch(
      updateSymptomOption({
        ...params,
        cb(res) {
          if (res.status === 200) {
            toast.success("Data Updated Successfully");
            props?.close();
            props?.getOneSymptom();
          } else {
            toast.error("Something Went Wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    setText(props?.item?.text ? props?.item?.text : "");
    setStatus(props?.item?.status ? props?.item?.status : "");
    setKey(props?.oneSymptom?.key ? props?.oneSymptom?.key : "");
    setTitle(props?.oneSymptom?.title ? props?.oneSymptom?.title : "");
    setId(props?.oneSymptom?.id ? props?.oneSymptom?.id : "");
  }, []);

  return (
    <>
      <div>
        <div className="mb-4">
          <label>Enter the text for options</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter your Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>

        <div className="outer-btn mb-3">
          <button onClick={updateSymptom} className="submit-btn">Update</button>
        </div>
      </div>
    </>
  );
}

export default UpdateSymptomModal;
