import React, { useEffect } from 'react';

const AppRedirect = () => {
  useEffect(() => {
    console.log("In-AppRedirect")
    const userAgent = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (isMobile) {
      const appPackageName = userAgent.match(/android/i) ? 'com.britetrace.app' : 'com.app.britetrace';
      const playStoreURL = userAgent.match(/android/i) ? 'https://play.google.com/store/apps/details?id=com.britetrace.app' : 'https://apps.apple.com/in/app/brite-trace/id6478534986';

      const checkAppInstalled = () => {
        if (appPackageName) {
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = `${appPackageName}://foo`;

          const timeout = setTimeout(() => {
            window.location.href = playStoreURL;
          }, 1000);
          window.addEventListener('pagehide', () => {
            clearTimeout(timeout);
          });

          document.body.appendChild(iframe);
        } else {
          console.error('App package name is not defined.');
        }
      };

      checkAppInstalled();

      // Cleanup function to remove event listener
      return () => {
        window.removeEventListener('pagehide', () => {});
      };
    }
  }, []);




  return (
    <div>
    </div>
  );
};

export default AppRedirect;
