import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  patientListByUserId,
} from "../../redux/slices/dashboard";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientDetail, setPatientDetail] = useState("");
  const getOnePatientDetail = () => {
    let params = {
      userId: id,
    };
    dispatch(
      patientListByUserId({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setPatientDetail(res?.data?.payload?.data[0]);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getOnePatientDetail();
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <div className="userDeatilHead">
                  <img
                    onClick={() => {
                      navigate("/users");
                    }}
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid cursor"
                  />
                  <h1 className="mainHead32">Patient Details</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="userDetailInfo">
          {patientDetail ? (
            <div className="userDetailLeft">
              <div className="userDeatilInner">
                <h6 className="userLabelText mt-2">
                  First Name: &nbsp;
                  <span className="labelBold">{patientDetail?.first_name}</span>
                </h6>
                <h6 className="userLabelText mt-2">
                  Last Name: &nbsp;
                  <span className="labelBold">{patientDetail?.last_name}</span>
                </h6>
                <h6 className="userLabelText mt-2">
                  Birth Date: &nbsp;
                  <span className="labelBold">
                    {moment(patientDetail?.birth_date)?.format("MMM DD, YYYY")}
                  </span>
                </h6>
                <h6 className="userLabelText mt-2">
                  Marital Status:&nbsp;
                  <span className="labelBold">
                    {patientDetail?.marital_status}
                  </span>
                </h6>

                <h6 className="userLabelText mt-2">
                  Birth City : &nbsp;
                  <span className="labelBold">{patientDetail?.birth_city}</span>
                </h6>

                {patientDetail?.children_name && (
                  <h6 className="userLabelText mt-2">
                    Children Name: &nbsp;
                    <span className="labelBold">
                      {patientDetail.children_name}
                    </span>
                  </h6>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No Patients Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDetail;
