import * as images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  symptomOptions,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CreateSymptomModal from "../Modal/createSymptomModal";
import Loader from "../../common/Loader";
import modalFlags from "../Modal/modalFlags.json"

const ArticleCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [symptoms, setSymptoms] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const getAllSymptoms = () => {
    setLoading(true);
    console.log(loading, "one");
    dispatch(
      symptomOptions({
        cb(res) {
          if (res?.data) {
            setLoading(false);
            setSymptoms(res?.data?.payload);
            console.log(loading, "two");
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleGoToDetailPage = (data) => {
    navigate(`/symptomOption/${data.key}`, {
      state: {
        oneSymptom: data,
      },
    });
  };

  useEffect(() => {
    getAllSymptoms();
  }, []);

  return (
    <>
      <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid">
            <div className="categories_group ps-0">
              <div className="col-sm-6">
                <h1 className="mainHead32">Symptoms</h1>
              </div>
              <div className="categories_btn ms-auto">
                <button
                  className="loginBtnCommon btnYellow add_btn"
                  onClick={() => {
                    handleUserProfile(modalFlags.createSymptomOptions);
                  }}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
          {loading && <Loader />}
          <div className="table-responsive recentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Title
                  </th>
                  <th scope="col" className="recentHead text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              {symptoms?.length > 0 ? (
                symptoms?.map((data, idx) => {
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{idx + 1}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover">
                              {data?.title}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead text-center">
                          <button
                            type="button"
                            className="activeUser"
                            onClick={(e) => handleGoToDetailPage(data)}
                          >
                            view
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                // <h3 className="text-center">No Symptoms Found!</h3>
                ""
              )}
            </table>
            {(!symptoms || symptoms?.length === 0) && (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No Symptoms Found
            </div>
          )}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === modalFlags.createSymptomOptions
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === modalFlags.createSymptomOptions
            ? "createSymptomOptions"
            : ""
        }
        child={
          modalDetail.flag === modalFlags.createSymptomOptions ? (
            <CreateSymptomModal
              getAllSymptoms={symptoms}
              getSymptom={getAllSymptoms}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === modalFlags.createSymptomOptions ? (
            <>
              <h2 className="modal_Heading">Create New</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default ArticleCategories;
