import { Route, Routes, Navigate } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import AuthLayout from "../layout/AuthLayout";
import * as Containers from "../containers";
import PrivateCheck from "../layout/ProtectRoute";
import AppRedirect from "../components/AppRedirect";

const Router = () => {
  const isLoggedIn = Boolean(localStorage.getItem("adminAuthToken"));
  return (
    <>
      <Routes>
        {/* DASHBOARD_ROUTES */}
        <Route element={<DashboardLayout />}>
          <Route element={<PrivateCheck auth={true} />}>
            <Route exact path="/users" element={<Containers.Users />} />
            <Route exact path="/patients" element={<Containers.Patients />} />
            <Route
              path="/change-password"
              element={<Containers.ChangePassword />}
            />
            <Route exact path="/guides" element={<Containers.Guide />} />
            <Route exact path="/screening" element={<Containers.Screening />} />
            <Route path="/symptoms" element={<Containers.Symptoms />} />
            <Route
              path="/symptomOption/:id"
              element={<Containers.SymptomOption />}
            />

            <Route path="/faq" element={<Containers.Faq />} />
            <Route path="/privacy" element={<Containers.Privacy />} />
            <Route path="userDetail/:id" element={<Containers.UserDetail />} />
            <Route
              path="guideDetail/:id"
              element={<Containers.GuideDetail />}
            />
            <Route path="/recallScreening" element={<Containers.RecallScreening/>}/>
            <Route path="/orientationScreening" element={<Containers.OrientationScreening/>}/>
            <Route path="/wordScreening" element={<Containers.WordScreening/>}/>

          </Route>
        </Route>

        {/* AUTH_ROUTES */}

        <Route element={<AuthLayout />}>
          <Route element={<PrivateCheck auth={false} />}>
            <Route path="/" element={<Containers.Login />} />
            <Route
              path="/forgot-password"
              element={<Containers.ForgotPassword />}
            />
            <Route
              path="/reset-password"
              element={<Containers.ResetPassword />}
            />
            <Route path="/enter-otp" element={<Containers.EnterOtp />} />
            <Route path="/privacy-policy" element={<Containers.PrivacyList />} />
            <Route path="*" element={<Containers.PageNotFound />} />
          </Route>
          <Route path="/share" element={<AppRedirect/>} />
          
        </Route>
      </Routes>
    </>
  );
};

export default Router;
