import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { screeningSymptoms } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";

const Screening = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [screening, setScreening] = useState("");

  const AllScreeningSymptoms = () => {
    setLoading(true);
    dispatch(
      screeningSymptoms({
        cb(res) {
          if (res?.data) {
            setScreening(res?.data?.payload);
            setLoading(false);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    AllScreeningSymptoms();
  }, []);

  return (
    <>
      <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid"></div>

          {loading && <Loader />}

          <div className="table-responsive recentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Title
                  </th>
                  <th scope="col" className="recentHead">
                    Description
                  </th>
                  <th scope="col" className="recentHead">
                    Action
                  </th>
                </tr>
              </thead>

              {screening?.length > 0 ? (
                screening?.map((screen, idx) => {
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{idx + 1}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover">
                              {screen?.key}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover desScreening">
                              {screen?.data[0]?.question}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover">
                              <Link
                                className="nameProfile"
                                to={
                                  screen.key === "recallScreening"
                                    ? "/recallScreening"
                                    : screen.key === "orientationScreening"
                                    ? "/orientationScreening"
                                    : screen.key === "wordScreening"
                                    ? "/wordScreening"
                                    : ""
                                }
                                state={{ screening: screening }}
                              >
                                Details
                              </Link>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                // <h3 className="text-center">No Screening Found!</h3>
                ""
              )}
            </table>
            {(!screening || screening?.length === 0) && (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No Symptoms Found
            </div>
          )}
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Screening;
