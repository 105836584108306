import { CKEditor } from "ckeditor4-react";
import { useState, useEffect } from "react";
import {
  privacyPolicy,
  privacyPolicyUpdate,
} from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useWebSelector } from "../../redux/selector/web";

const Contact = () => {
  const { loading } = useWebSelector();
  const { buttonLoading } = useWebSelector();
  const dispatch = useDispatch();
  const [dataa, setDataa] = useState();
  const [slug, setSlug] = useState("privacy_policy");
  const [ckKey, setCkKey] = useState(Math.random());
  const [id, setId] = useState("");
  const [terms, setTerms] = useState();
  const [about, setAbout] = useState();
  const [privacy, setPrivacy] = useState(undefined);
  const [title, setTitle] = useState(
    "Term and Conditions for Brite  Trace1181"
  );
  console.log(loading);


  const getPrivacyList = () => {
    const params = {
      slug: slug,
    };
    console.log(slug, "currentSlugggg");

    dispatch(
      privacyPolicy({
        ...params,
        cb(res) {
          if (res?.data) {
            console.log(res?.data, "myREsponseDataaaaaaaaaa");
            if (slug === "privacy_policy") {
              setPrivacy(res?.data?.payload?.content);
              setTerms(undefined);
              setAbout(undefined);
              setId(res?.data?.payload?.id);
            } else if (slug === "term_and_conditions") {
              setTerms(res?.data?.payload?.content);
              setPrivacy(undefined);
              setAbout(undefined);

              setId(res?.data?.payload?.id);
            } else if (slug === "about_us") {
              setAbout(res?.data?.payload?.content);
              setPrivacy(undefined);
              setTerms(undefined);
              setId(res?.data?.payload?.id);
            }
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleUpdate = () => {
    const params = {
      id: id,
      title: title,
      content: dataa,
      annotations: [],
    };

    dispatch(
      privacyPolicyUpdate({
        ...params,
        cb(res) {
          if (res?.data) {
            console.log(res?.data?.payload?.content, "content1");
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const [editorVisible, setEditorVisible] = useState(false);
  const handleEditorChange = (event, editor) => {
    const data = event.editor.getData();
    const plainText = stripHtmlTags(data);
    setDataa(plainText);
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    getPrivacyList();
  }, [slug]);

  useEffect(() => {
    getPrivacyList();
  }, []);



  return (
    <div>
      {loading ? (
        <span className="spinner-border spinner-border-sm ms-1"></span>
      ) : (
        <>
          <button
            className={`${
              slug === "privacy_policy"
                ? "activeTab mb-3 mt-3 me-3"
                : "inActiveTab me-3"
            }`}
            onClick={() => {
              setSlug("privacy_policy");
            }}
          >
            Privacy Policy
            {buttonLoading && (
              <span className="spinner-border spinner-border-sm ms-1"></span>
            )}
          </button>
        </>
      )}

      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm ms-1"></span>
        </>
      ) : (
        <button
          className={`${
            slug === "term_and_conditions"
              ? "activeTab mb-3 mt-3 "
              : "inActiveTab "
          }`}
          onClick={() => {
            setSlug("term_and_conditions");
          }}
        >
          Terms & Condition
          {buttonLoading && (
            <span className="spinner-border spinner-border-sm me-1"></span>
          )}
        </button>
      )}

      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm ms-1"></span>
        </>
      ) : (
        <button
          className={`${
            slug === "about_us"
              ? "activeTab mb-3 mt-3 ms-3"
              : "inActiveTab ms-3"
          }`}
          onClick={() => {
            setSlug("about_us");
          }}
        >
          About Us
          {buttonLoading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      )}

      {/* )} */}

      {/* <h1 style={{ color: "white" }}>No privacy policy yet</h1> */}
      {/* {data && slug === "privacy_policy" && (
        <CKEditor
          key={ckKey}
          initData={data}
          config={{
            removePlugins: [
              "EasyImage",
              "ImageUpload",
              "MediaEmbed",
              "Table",
              "TableToolbar",
              "image",
            ],
            toolbarLocation: ["top"],
            removeButtons:
              "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
          }}
          name="editor"
          readOnly={false}
          onChange={(event, editor) => {
            const data = event.editor.getData();
            console.log(data);
            setData(data);
          }}
          type="classic"
        />
      )}
      {terms && slug === "term_and_conditions" && (
        <CKEditor
          key={ckKey}
          initData={terms}
          config={{
            removePlugins: [
              "EasyImage",
              "ImageUpload",
              "MediaEmbed",
              "Table",
              "TableToolbar",
              "image",
            ],
            toolbarLocation: ["top"],
            removeButtons:
              "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
          }}
          name="editor"
          readOnly={false}
          onChange={(event, editor) => {
            const data = event.editor.getData();
            console.log(data);
            setData(data);
          }}
          type="classic"
        />
      )}  */}
      <div
        // style={{ height: "40vh" }}
        className={`${slug !== "privacy_policy" ? "d-none" : ""}`}
      >
        {privacy !== undefined && slug === "privacy_policy" && (
          <CKEditor
            key={ckKey}
            initData={privacy}
            config={{
              versionCheck: false,
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "TableToolbar",
                "image",
              ],
              toolbarLocation: ["top"],
              removeButtons:
                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
            }}
            name="editor"
            readOnly={false}
            // onChange={(event, editor) => {
            //   const data = event.editor.getData();
            //   console.log("vaishnavi sharma");
            //   setDataa(data);
            // }}
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      <div
        // style={{ height: "40vh" }}
        className={`${slug !== "term_and_conditions" ? "d-none" : ""}`}
      >
        {terms !== undefined && slug === "term_and_conditions" && (
          <CKEditor
            key={ckKey}
            initData={terms}
            config={{
              versionCheck: false,
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "TableToolbar",
                "image",
              ],
              toolbarLocation: ["top"],
              removeButtons:
                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
            }}
            name="editor"
            readOnly={false}
          
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      <div
        className={`${slug !== "about_us" ? "d-none" : ""}`}
      >
        {console.log(about, "about my name ")}
        {about !== undefined && slug === "about_us" && (
          <CKEditor
            key={ckKey}
            initData={about}
            config={{
              versionCheck: false,
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "TableToolbar",
                "image",
              ],
              toolbarLocation: ["top"],
              removeButtons:
                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
            }}
            name="editor"
            readOnly={false}
         
            onChange={handleEditorChange}
            type="classic"
          />
        )}
      </div>
      {loading ? (
        <>
          {" "}
          <span className="spinner-border spinner-border-sm ms-1"></span>
        </>
      ) : (
        <>
          {dataa && (
            <button className="updateBtn_ mt-3" onClick={handleUpdate}>
              Update
              {buttonLoading && (
                <span className="spinner-border spinner-border-sm ms-1"></span>
              )}
            </button>
          )}
        </>
      )}
    </div>
  );
};
export default Contact;
