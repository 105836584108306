import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import * as images from "../../utilities/images";
import CreateOrientationOption from "../Modal/createOrientationOption";
import CreateOrientationQuestion from "../Modal/createOrientationQuestion";
import { getOneScreeningSymptoms } from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";

function OrientationScreening() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [screen, setScreen] = useState();
  const [key, setKey] = useState(Math.random());
  const pathName = window.location.pathname;
  const parts = pathName.split("/");
  const pathkey = parts[parts.length - 1];

  const screeningData = state?.screening.find(
    (item) => item.key === "orientationScreening"
  );
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleCreateModal = (
    flag,
    questionId,
    question,
    optionId,
    optionText
  ) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      questionId: questionId,
      Question: question,
      optionId: optionId,
      optionText: optionText,
    });
    setKey(Math.random());
  };

  const oneScreening = () => {
    let params = {
      screeningId: screeningData?.id,
      key: pathkey,
    };
    dispatch(
      getOneScreeningSymptoms({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setScreen(res?.data?.payload);
          } else {
            console.log("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    oneScreening();
  }, []);

  return (
    <div className="content-wrapper categories">
      <div className="content-header">
        <div className="container-fluid">
          <>
            <img
              onClick={() => {
                navigate("/screening");
              }}
              src={images.arrowMain}
              alt="arrowMainimg"
              className="img-fluid pb-3 me-3 cursor"
            />
            <h1 className="text-capital d-inline-block mb-3">
              Title: OrientationScreening
            </h1>
            {screen ? (
              <div>
                {screen.data && screen.data.length > 0 ? (
                  <>
                    <button
                      className="activeUser"
                      onClick={() => {
                        handleCreateModal("createQuestion");
                      }}
                    >
                      Create Question
                    </button>
                    {screen.data.map((item, i) => (
                      <div key={i} className="mt-4">
                        <p className=" fw-bold mainOption">
                          <b className="me-3">
                            Question {i + 1}: {item.question}
                          </b>
                          <button
                            className="activeUser me-3"
                            onClick={() => {
                              handleCreateModal(
                                "createOption",
                                item.id,
                                item.question
                              );
                            }}
                          >
                            Create Option
                          </button>
                          <button
                            className="activeUser"
                            onClick={() => {
                              handleCreateModal(
                                "updateQuestion",

                                item.id,
                                item.question
                              );
                            }}
                          >
                            Update Question
                          </button>
                        </p>
                        {item.options && item.options.length > 0 && (
                          <div className="mb-4">
                            <ul className="option_">
                              {item.options.map((option, index) => (
                                <li key={index} className=" mainOption">
                                  <span className="me-2">
                                    Option {index + 1} :{" "}
                                  </span>{" "}
                                  <span className="me-3">{option?.text}</span>{" "}
                                  <button
                                    className="activeUser"
                                    onClick={() => {
                                      handleCreateModal(
                                        "updateOption",
                                        item.id,
                                        item.question,
                                        option.id,
                                        option.text
                                      );
                                    }}
                                  >
                                    Update Option
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="d-flex justify-content-center my-4 fs-4 text-white">
                  No Data Available
                </div>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No screening data available
            </div>
            )}
            

            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={false}
              isRightSideModal={true}
              mediumWidth={false}
              className={
                modalDetail.flag === "createSubOption"
                  ? "commonWidth customContent"
                  : ""
              }
              ids={modalDetail.flag === "createOption" ? "createOption" : ""}
              child={
                modalDetail.flag === "createOption" ? (
                  <CreateOrientationOption
                    screeningData={screen}
                    questionId={modalDetail.questionId}
                    optionTitle={modalDetail.optionTitle}
                    question={modalDetail.Question}
                    dataId={modalDetail.dataId}
                    oneScreening={oneScreening}
                    close={() => handleOnCloseModal()}
                  />
                ) : modalDetail.flag === "createQuestion" ? (
                  <CreateOrientationQuestion
                    screeningData={screen}
                    oneScreening={oneScreening}
                    close={() => handleOnCloseModal()}
                  />
                ) : modalDetail.flag === "updateQuestion" ? (
                  <CreateOrientationQuestion
                    isEdit={true}
                    question={modalDetail.Question}
                    questionId={modalDetail.questionId}
                    screeningData={screen}
                    oneScreening={oneScreening}
                    close={() => handleOnCloseModal()}
                  />
                ) : modalDetail.flag === "updateOption" ? (
                  <CreateOrientationOption
                    isEdonId={modalDetail.questionId}
                    Questiit={true}
                    question={modalDetail.Question}
                    optionId={modalDetail.optionId}
                    optionText={modalDetail.optionText}
                    screeningData={screen}
                    oneScreening={oneScreening}
                    close={() => handleOnCloseModal()}
                  />
                ) : (
                  ""
                )
              }
              header={
                modalDetail.flag === "createOption" ? (
                  <>
                    <h2 className="modal_Heading">Create Options</h2>
                    <p onClick={handleOnCloseModal} className="modal_cancel">
                      <img
                        src={images.modalCross}
                        className="ModalCance"
                        alt=""
                      />
                    </p>
                  </>
                ) : modalDetail.flag === "createQuestion" ? (
                  <>
                    <h2 className="modal_Heading">Create Question</h2>
                    <p onClick={handleOnCloseModal} className="modal_cancel">
                      <img
                        src={images.modalCross}
                        className="ModalCance"
                        alt=""
                      />
                    </p>
                  </>
                ) : modalDetail.flag === "updateQuestion" ? (
                  <>
                    <h2 className="modal_Heading"> Update Question</h2>
                    <p onClick={handleOnCloseModal} className="modal_cancel">
                      <img
                        src={images.modalCross}
                        className="ModalCance"
                        alt=""
                      />
                    </p>
                  </>
                ) : modalDetail.flag === "updateOption" ? (
                  <>
                    <h2 className="modal_Heading"> Update Option</h2>
                    <p onClick={handleOnCloseModal} className="modal_cancel">
                      <img
                        src={images.modalCross}
                        className="ModalCance"
                        alt=""
                      />
                    </p>
                  </>
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          </>
        </div>
      </div>
    </div>
  );
}

export default OrientationScreening;
