import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import UpdateSymptomModal from "../Modal/updateSymptomModal";
import {
  deleteSymptomOption,
  getOneSymptomsOptions,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import modalFlags from "../Modal/modalFlags.json"

function SymptomOption() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const one = location.state;
  const [loading, setLoading] = useState(false);
  const { oneSymptom } = one;
  const [key, setKey] = useState(Math.random());
  const onesymptom = oneSymptom;
  const [title, setTitle] = useState("");
  const [id, setID] = useState();
  const [singleSymptom, setSingleSymptom] = useState();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleGuideModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      data: data,
    });
    setKey(Math.random());
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      data: "",
    });
    setKey(Math.random());
  };

  const GetOneSymptom = () => {
    let params = {
      id: oneSymptom?.id,
      key: oneSymptom?.key,
    };
    setLoading(true);
    dispatch(
      getOneSymptomsOptions({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setSingleSymptom(res?.data?.payload?.options);
            setLoading(false);
          } else {
            toast.error("Something Went Wrong");
          }
        },
      })
    );
  };

  const deleteHandle = (val) => {
    Swal.fire({
      title: "Do you want to delete this ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        const optionId = val;

        if (onesymptom && onesymptom?.options) {
          const optionIndex = onesymptom?.options?.findIndex(
            (option) => option?.id === optionId
          );

          if (optionIndex !== -1) {
            onesymptom?.options?.splice(optionIndex, 1);

            let params = {
              key: key,
              title: title,
              id: id,
              options: onesymptom?.options,
            };

            dispatch(
              deleteSymptomOption({
                ...params,
                cb(res) {
                  if (res.status === 200) {
                    GetOneSymptom();
                  } else {
                  }
                },
              })
            );
          } else {
          }
        }
      }
    });
  };

  useEffect(() => {
    setTitle(oneSymptom?.title ? oneSymptom?.title : "");
    setKey(oneSymptom?.key ? oneSymptom?.key : "");
    setID(oneSymptom?.id ? onesymptom?.id : "");
  });
  useEffect(() => {
    GetOneSymptom();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <div className="userDeatilHead">
                  <img
                    onClick={() => {
                      navigate("/symptoms");
                    }}
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid cursor"
                  />
                  <h1 className="mainHead32">Symptoms Options</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <Loader />}
        <div className="table-responsive recentTable">
          <table class="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th scope="col" className="recentHead">
                  SR. NO.
                </th>
                <th scope="col" className="recentHead">
                  Title
                </th>

                <th scope="col" className="recentHead text-center">
                  Actions
                </th>
              </tr>
            </thead>

            {singleSymptom?.length > 0 ? (
              singleSymptom?.map((option, idx) => {
                return (
                  <tbody>
                    <tr>
                      <td className="recentSubHead">
                        <h4 className="tableSubText">{idx + 1}</h4>
                      </td>
                      <td className="recentSubHead">
                        <div className="nameProfile">
                          <p className="tableSubText underlineHover">
                            {option?.text}
                          </p>
                        </div>
                      </td>

                      <td className="recentSubHead text-center">
                        <div>
                          <button
                            className="activeUser mr-2"
                            onClick={() => deleteHandle(option?.id)}
                          >
                            Delete
                          </button>

                          <button
                            className="activeUser"
                            onClick={() =>
                              handleGuideModal(modalFlags.updateGuide, option)
                            }
                          >
                            Update
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              // <h3 className="text-center">No Symptom Option Found!</h3>
              ""
            )}
          </table>
          {(!singleSymptom || singleSymptom?.length === 0) && (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No Symptom Option Found!
            </div>
          )}


          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={true}
            mediumWidth={false}
            className={
              modalDetail.flag === modalFlags.updateGuide
                ? "commonWidth customContent"
                : ""
            }
            ids={modalDetail.flag === modalFlags.updateGuide ? modalFlags.updateGuide : ""}
            child={
              modalDetail.flag === modalFlags.updateGuide ? (
                <UpdateSymptomModal
                  isEdit={true}
                  item={modalDetail.data}
                  oneSymptom={onesymptom}
                  getOneSymptom={GetOneSymptom}
                  close={() => handleOnCloseModal()}
                />
              ) : (
                ""
              )
            }
            header={
             modalDetail.flag === modalFlags.updateGuide ? (
                <>
                  <h2 className="modal_Heading">Symptom Update</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </div>
    </>
  );
}

export default SymptomOption;
