import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as images from "../../utilities/images";
import Swal from "sweetalert2";
import CustomModal from "../Modal/CustomModal";
import {
  behaviourGuideById,
  behaviourReportsbyId,
  deleteGuide,
} from "../../redux/slices/dashboard";
import CreateGuides from "../Modal/createGuideModal";
import UpdateGuide from "../Modal/updateGuideModal";
import BehaviorGuideView from "../Modal/behaviorGuideViewModal";

function GuideDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oneGuide, setOneGuide] = useState();
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleGuideModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      data: data,
    });
    setKey(Math.random());
  };
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      data: "",
    });
    setKey(Math.random());
  };

  const getOneBehaviourGuide = () => {
    let params = {
      id: id,
    };
    dispatch(
      behaviourReportsbyId({
        ...params,
        cb(res) {
          console.log("getoneGuideResponseData", res);
          if (res?.status === 200) {
            setOneGuide(res?.data?.payload?.behavior_guide);
          } else {
            console.log("something went wrong");
          }
        },
      })
    );
  };

  const deleteHandle = (val) => {
    Swal.fire({
      title: "Do you want to delete this ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          id: val,
        };

        dispatch(
          deleteGuide({
            ...params,
            cb(res) {
              if (res.status === 200) {
                getOneBehaviourGuide();
              } else {
                console.log("there is some error in the Guide");
              }
            },
          })
        );
      }
    });
  };

  useEffect(() => {
    getOneBehaviourGuide();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <div className="userDeatilHead">
                  <img
                    onClick={() => {
                      navigate("/guides");
                    }}
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid cursor"
                  />
                  <h1 className="mainHead32">Behavior Guides Details</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive recentTable">
          <table class="table table-dark m-0 guidess">
            <thead>
              <tr className="recentRow">
                <th scope="col" className="recentHead">
                  SR. NO.
                </th>
                <th scope="col" className="recentHead">
                  Title
                </th>

                <th scope="col" className="recentHead">
                  Description
                </th>

                <th scope="col" className="recentHead">
                  Duration
                </th>
                <th scope="col" className="recentHead text-center">
                  Actions
                </th>
              </tr>
            </thead>

            {oneGuide?.length > 0 ? (
              oneGuide?.map((guide, idx) => {
                //   const index = idx + 1 + (page - 1) * 10;
                return (
                  <tbody>
                    <tr>
                      <td className="recentSubHead">
                        <h4 className="tableSubText">{idx + 1}</h4>
                      </td>
                      <td className="recentSubHead">
                        <div className="nameProfile">
                          <p className="tableSubText underlineHover">
                            {guide?.title}
                          </p>
                        </div>
                      </td>
                      <td className="recentSubHead">
                        <div className="nameProfile">
                          <p className="tableSubText underlineHover">
                            {guide?.description}
                          </p>
                        </div>
                      </td>

                      <td className="recentSubHead">
                        <h4 className="tableSubText">{guide?.duration}</h4>
                      </td>

                      <td className="recentSubHead text-center">
                        <div>
                          <button
                            className="activeUser mr-2"
                            onClick={() => deleteHandle(guide?.id)}
                          >
                            Delete
                          </button>

                          <button
                            className="activeUser mr-2"
                            onClick={() =>
                              handleGuideModal("updateGuide", guide)
                            }
                          >
                            Update
                          </button>
                          <button
                            className="activeUser"
                            onClick={() => handleGuideModal("viewGuide", guide)}
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              // <h3 className="text-center">No Behavior Guides Found!</h3>
              ""
            )}
          </table>
          {(!oneGuide || oneGuide?.length === 0) && (
            <div className="d-flex justify-content-center my-4 fs-4 text-white">
              No  Behavior Guides Found
            </div>
          )}

          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={true}
            mediumWidth={false}
            className={
              modalDetail.flag === "createGuide"
                ? "commonWidth customContent"
                : ""
            }
            ids={
              modalDetail.flag === "createGuide"
                ? "createGuide"
                : modalDetail.flag === "updateGuide"
                ? "updateGuide"
                : ""
            }
            child={
              modalDetail.flag === "updateGuide" ? (
                <UpdateGuide
                  isEdit={true}
                  item={modalDetail.data}
                  oneGuide={getOneBehaviourGuide}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "viewGuide" ? (
                <BehaviorGuideView
                  data={modalDetail.data}
                  close={() => handleOnCloseModal()}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "createGuide" ? (
                <>
                  <h2 className="modal_Heading">Create New</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : modalDetail.flag === "viewGuide" ? (
                <>
                  <h2 className="modal_Heading">Behavior Guide View</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : modalDetail.flag === "updateGuide" ? (
                <>
                  <h2 className="modal_Heading">Guide Update</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </div>
    </>
  );
}

export default GuideDetail;
