import React from 'react'

function BehaviorGuideView(props) {
    const dataa = props?.data;
  return (
    <>
    <p className='faqData'><span className='faqSpan'>Title:</span> {dataa.title}?</p> 
    <p className='faqData'><span className='faqSpan'>Description:</span> {dataa.description}?</p> 
    
    </>
  )
}

export default BehaviorGuideView