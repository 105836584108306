import React, { useState } from "react";
import CustomModal from "../Modal/CustomModal";
import * as images from "../../utilities/images";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { deleteGuide } from "../../redux/slices/dashboard";
import UpdateGuide from "./updateGuideModal";
import modalFlags from "./modalFlags.json";

function ViewGuide({ guide }) {
  const { behavior_guide } = guide;
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const dispatch = useDispatch();

  const handleGuideModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      data: data,
    });
    setKey(Math.random());
  };
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      data: "",
    });
    setKey(Math.random());
  };

  const deleteHandle = (val) => {
    Swal.fire({
      title: "Do you want to delete this ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: val,
        };

        dispatch(
          deleteGuide({
            ...params,
            cb(res) {
              if (res.status === 200) {
                // faqList();
              } else {
                console.log("there is some error in the Guide");
              }
            },
          })
        );
      }
    });
  };

  return (
    <>
      {behavior_guide && behavior_guide.length > 0 ? (
        <div className="behavior-guide">
          <h4>Behavior Guide:</h4>
          <ul>
            {behavior_guide.map((item, index) => (
              <div>
                <li key={index}>{item.title}</li>
                <li key={index}>{item.id}</li>
                <button onClick={() => handleGuideModal(modalFlags.updateGuide, item)}>
                  Update
                </button>
                <button onClick={() => deleteHandle(item.id)}>Delete</button>
              </div>
            ))}
          </ul>
        </div>
      ) : (
        <p className="black">No Behaviour Guides Found</p>
      )}

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === modalFlags.updateGuide ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === modalFlags.updateGuide ? "updateGuide" : ""}
        child={
          modalDetail.flag === modalFlags.updateGuide ? (
            <UpdateGuide
              isEdit={true}
              item={modalDetail.data}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === modalFlags.updateGuide ? (
            <>
              <h2 className="modal_Heading">Guide Update</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
}

export default ViewGuide;
