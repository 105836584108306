import React from "react";
import { useState, useEffect } from "react";
import { privacyPolicy } from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function PrivacyList() {

  const dispatch = useDispatch();
  const [slug, setSlug] = useState("privacy_policy");
  const [id, setId] = useState("");
  const [terms, setTerms] = useState();
  const [about, setAbout] = useState();
  const [privacy, setPrivacy] = useState(undefined);

  const getPrivacyList = () => {
    const params = {
      slug: slug,
    };

    dispatch(
      privacyPolicy({
        ...params,
        cb(res) {

          if (res?.data) {
            if (slug === "privacy_policy") {
              setPrivacy(res?.data?.payload?.content);
              setTerms(undefined);
              setAbout(undefined);
              setId(res?.data?.payload?.id);
            } else if (slug === "term_and_conditions") {
              setTerms(res?.data?.payload?.content);
              setPrivacy(undefined);
              setAbout(undefined);

              setId(res?.data?.payload?.id);
            } else if (slug === "about_us") {
              setAbout(res?.data?.payload?.content);
              setPrivacy(undefined);
              setTerms(undefined);
              setId(res?.data?.payload?.id);
            }
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
 
  useEffect(() => {
    getPrivacyList();
  }, []);
 
  return (
    <div className="authBg height">
      <div>
        <p className="privacy_list_head .logintext">PRIVACY POLICY</p>
        <p className="privacy_list_data">{privacy}</p>
      </div>
    </div>
  );
}

export default PrivacyList;
